.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.animated-button {
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: white;
  cursor: pointer;
  border: none;
  max-width: 90%;
}

.animated-button a {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.animated-button:active,
.animated-button:focus {
  outline: none;
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

/* let's shape */
.animated-button .shape {
  padding: 0 1em;
  position: relative;
  top: 0;
  left: 0;
}

.animated-button .shape span {
  content: '';
  position: absolute;
  margin: auto;
  height: 8px;
  width: 2px;
  opacity: 0;
}

.animated-button .shape span:nth-of-type(1) {
  top: -20px;
  left: 0;
  right: 0;
}

.animated-button .shape span:nth-of-type(2) {
  bottom: -20px;
  left: 0;
  right: 0;
}

.animated-button .shape span:nth-of-type(3) {
  top: 0;
  bottom: 0;
  left: -10px;
}

.animated-button .shape span:nth-of-type(4) {
  top: 0;
  bottom: 0;
  right: -10px;
}

.animated-button .shape span:nth-of-type(5) {
  top: -20px;
  left: -70%;
  right: 0;
  transform: rotate(-30deg);
}

.animated-button .shape span:nth-of-type(6) {
  top: -20px;
  left: 0;
  right: -70%;
  transform: rotate(30deg);
}

.animated-button .shape span:nth-of-type(7) {
  bottom: -20px;
  right: 0;
  left: -70%;
  transform: rotate(30deg);
}

.animated-button .shape span:nth-of-type(8) {
  bottom: -20px;
  left: 0;
  right: -70%;
  transform: rotate(-30deg);
}

/* let's animate this */
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animated-button.active {
  animation: bounce 0.3s ease-out 1;
}

.animated-button.active .shape span:nth-of-type(1) {
  animation: anim1 0.4s ease-out 1;
}
.animated-button.active .shape span:nth-of-type(2) {
  animation: anim2 0.4s ease-out 1;
}
.animated-button.active .shape span:nth-of-type(3) {
  animation: anim3 0.4s ease-out 1;
}
.animated-button.active .shape span:nth-of-type(4) {
  animation: anim4 0.4s ease-out 1;
}
.animated-button.active .shape span:nth-of-type(5) {
  animation: anim5 0.4s ease-out 1;
}
.animated-button.active .shape span:nth-of-type(6) {
  animation: anim6 0.4s ease-out 1;
}
.animated-button.active .shape span:nth-of-type(7) {
  animation: anim7 0.4s ease-out 1;
}
.animated-button.active .shape span:nth-of-type(8) {
  animation: anim8 0.4s ease-out 1;
}

@keyframes anim1 {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
  50% {
    transform: translateY(-10px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px) scaleY(0.5);
    opacity: 0;
  }
}
@keyframes anim2 {
  0% {
    transform: scaleY(1);
    opacity: 0;
  }
  50% {
    transform: translateY(10px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    transform: translateY(20px) scaleY(0.5);
    opacity: 0;
  }
}
@keyframes anim3 {
  0% {
    transform: rotate(90deg) scaleX(0.5);
    opacity: 0;
  }
  50% {
    transform: rotate(90deg) translateY(10px) scaleX(1.4);
    opacity: 1;
  }
  100% {
    transform: rotate(90deg) translateY(20px) scaleX(0.5);
    opacity: 0;
  }
}
@keyframes anim4 {
  0% {
    transform: rotate(90deg) scaleX(0.5);
    opacity: 0;
  }
  50% {
    transform: rotate(90deg) translateY(-10px) scaleX(1.4);
    opacity: 1;
  }
  100% {
    transform: rotate(90deg) translateY(-20px) scaleX(0.5);
    opacity: 0;
  }
}
@keyframes anim5 {
  0% {
    transform: rotate(-30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    transform: rotate(-30deg) translateY(-8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    transform: rotate(-30deg) translateY(-16px) scaleY(0.5);
    opacity: 0;
  }
}
@keyframes anim6 {
  0% {
    transform: rotate(30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    transform: rotate(30deg) translateY(-8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    transform: rotate(30deg) translateY(-16px) scaleY(0.5);
    opacity: 0;
  }
}
@keyframes anim7 {
  0% {
    transform: rotate(30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    transform: rotate(30deg) translateY(8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    transform: rotate(30deg) translateY(16px) scaleY(0.5);
    opacity: 0;
  }
}
@keyframes anim8 {
  0% {
    transform: rotate(-30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    transform: rotate(-30deg) translateY(8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    transform: rotate(-30deg) translateY(16px) scaleY(0.5);
    opacity: 0;
  }
}
