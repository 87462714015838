.title {
  font-weight: lighter;
}

.app-title {
  text-align: center;
  font-size: calc(1em + 7vw);
  margin: 5rem 1rem;
}

@media (min-width: 1000px) {
  .app-title {
    font-size: calc(1em + 70px);
  }
}

.animated-button-container {
  text-align: center;
  margin: 5rem 1rem;
}
