.profile-card-container {
  margin: 10em auto;
  text-align: center;
}

.profile-card {
  max-width: 400px;
  margin: auto;
}

.profile-button {
  width: 100%;
}

.profile-avatar {
  width: 100%;
  height: 100%;
}

.profile-banner {
  text-align: center;
  margin: 5rem 1rem;
}

.profile-status {
  text-align: center;
}

.profile {
  margin: 2.5em auto;
}

.profile-avatar {
  width: 10em;
  height: 10em;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin: 0.8em auto;
}

.profile-avatar img {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}
